import React from "react"
import Layout from "../components/layout"

export default function FourOhFour () {
	return(
		<Layout>
			<h1>Sorry, content not found.</h1>
		</Layout>
	)
}
